import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import withAuth from "../../auth";
import { API_URL } from '../../constants';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { AuthContext } from "../AuthContext";
import ReactApexChart from 'react-apexcharts';

const CustomerSignupStatisticsByWeek = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [period, setPeriod] = useState(searchParams.get('period') || 'this_month');
    const [startDate, setStartDate] = useState(searchParams.get('start_date') || '');
    const [endDate, setEndDate] = useState(searchParams.get('end_date') || '');
    const [statistics, setStatistics] = useState([]);
    const [totals, setTotals] = useState({});
    const [chartData, setChartData] = useState({
        categories: [],
        series: []
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showDateInputs, setShowDateInputs] = useState(period === 'select_period');

    const navigate = useNavigate();
    const { token, setToken } = useContext(AuthContext);

    useEffect(() => {
        const newPeriod = searchParams.get('period') || 'this_month';
        const newStartDate = searchParams.get('start_date') || '';
        const newEndDate = searchParams.get('end_date') || '';
        setPeriod(newPeriod);
        setStartDate(newStartDate);
        setEndDate(newEndDate);
    }, [searchParams]);

    useEffect(() => {
        if (period !== 'select_period' || (startDate && endDate)) {
            fetchStatistics();
        }
        setShowDateInputs(period === 'select_period');
    }, [period, startDate, endDate]);

    const fetchStatistics = async () => {
        setLoading(true);
        setError(null);
        try {
            const params = {
                period: period,
            };
            if (period === 'select_period') {
                params.start_date = startDate;
                params.end_date = endDate;
            }
            const response = await axios.get(`${API_URL}/api/admin/users/statistics/signups/by_week`, {
                params: params,
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            setStatistics(response.data.data);
            setTotals(response.data.totals);
            prepareChartData(response.data.data);
        } catch (error) {
            console.error("There was an error fetching the statistics!", error);
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                setToken(null);
                navigate('/');
            } else {
                setError("Failed to fetch statistics. Please try again.");
            }
        } finally {
            setLoading(false);
        }
    };

    const handleDateChange = (e) => {
        const { name, value } = e.target;
        setError(null);
        if (name === 'startDate') {
            setStartDate(value);
            setSearchParams({ period: period, start_date: value, end_date: endDate });
        } else if (name === 'endDate') {
            setEndDate(value);
            setSearchParams({ period: period, start_date: startDate, end_date: value });
        }
    };

    // Helper function to get the date of the first day of a given ISO week and year
    const getDateOfISOWeek = (week, year) => {
        const simple = new Date(year, 0, 4 + (week - 1) * 7);
        const dow = simple.getDay();
        const ISOweekStart = new Date(simple);
        if (dow <= 4)
            ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
        else
            ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
        return ISOweekStart;
    };

    const prepareChartData = (data) => {
        const sortedData = [...data].sort((a, b) => {
            const dateA = getDateOfISOWeek(a.week, a.year);
            const dateB = getDateOfISOWeek(b.week, b.year);
            return dateA - dateB;
        });

        const categories = sortedData.map(stat => {
            const startDate = getDateOfISOWeek(stat.week, stat.year);
            const endDate = new Date(startDate);
            endDate.setDate(startDate.getDate() + 6);

            const startDateStr = startDate.toLocaleDateString('default', { month: 'short', day: 'numeric' });
            const endDateStr = endDate.toLocaleDateString('default', { month: 'short', day: 'numeric', year: 'numeric' });

            return `${startDateStr} - ${endDateStr}`;
        });

        const signupCounts = sortedData.map(stat => stat.count);

        setChartData({
            categories: categories,
            series: [
                {
                    name: 'Signups',
                    data: signupCounts
                }
            ]
        });
    };

    const chartOptions = {
        chart: {
            type: 'bar',
            height: 350,
            zoom: {
                enabled: true,
            },
            toolbar: {
                show: true,
            },
        },
        colors: ['#28a745'],
        dataLabels: {
            enabled: false,
        },
        xaxis: {
            categories: chartData.categories,
            labels: {
                rotate: -90,
                maxHeight: 120,
                style: {
                    fontSize: '10px',
                },
            },
            title: {
                text: 'Week',
            },
        },
        yaxis: {
            min: 0,
            forceNiceScale: true,
            title: {
                text: 'Signups',
            },
            labels: {
                formatter: function (value) {
                    return Math.round(value).toString();
                },
            },
        },
        legend: {
            show: false,
        },
        tooltip: {
            y: {
                formatter: function (value) {
                    return Math.round(value).toString();
                },
            },
        },
    };

    return (
        <div className="container-fluid mt-3">
            <h2 className="text-center">Customer Signups by Week</h2>
            <div className="form-group text-center">
                <span>Period: </span>
                {['this_month', 'last_month', 'this_year', 'select_period'].map((timePeriod, index) => (
                    <React.Fragment key={timePeriod}>
                        <Link
                            to={`?period=${timePeriod}`}
                            className={`period-link ${period === timePeriod ? 'active' : ''}`}
                        >
                            {timePeriod
                                .replace('this_month', 'This Month')
                                .replace('last_month', 'Last Month')
                                .replace('this_year', 'This Year')
                                .replace('select_period', 'Custom Range')}
                        </Link>
                        {index < 3 && <span> / </span>}
                    </React.Fragment>
                ))}
            </div>
            <div className={`collapse ${showDateInputs ? 'show' : ''}`}>
                <div className="form-group row justify-content-center">
                    <div className="col-auto">
                        <label htmlFor="startDate" className="col-form-label">Start Date:</label>
                    </div>
                    <div className="col-auto">
                        <input
                            type="date"
                            id="startDate"
                            name="startDate"
                            value={startDate}
                            onChange={handleDateChange}
                            className="form-control"
                        />
                    </div>
                    <div className="col-auto">
                        <label htmlFor="endDate" className="col-form-label">End Date:</label>
                    </div>
                    <div className="col-auto">
                        <input
                            type="date"
                            id="endDate"
                            name="endDate"
                            value={endDate}
                            onChange={handleDateChange}
                            className="form-control"
                        />
                    </div>
                </div>
            </div>
            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : error ? (
                <div className="alert alert-danger text-center" role="alert">
                    {error}
                </div>
            ) : (
                statistics.length === 0 ? (
                    <p className="text-center">No data available for the selected period.</p>
                ) : (
                    <>
                        <div className="total-signups d-flex justify-content-center mt-4">
                            <div className="text-center mx-3">
                                <h4>{Math.round(totals.count)}</h4>
                                <label>Total Signups</label>
                            </div>
                        </div>

                        <div className="card mt-4">
                            <div className="card-body">
                                <div id="signupChart" className="mt-4">
                                    <ReactApexChart
                                        options={chartOptions}
                                        series={chartData.series}
                                        type="bar"
                                        height={350}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="table-responsive mt-4">
                            <h4 className="text-center">Weekly Customer Signups</h4>
                            <table className="table table-sm table-striped text-center">
                                <thead>
                                <tr>
                                    <th>Week (Date Range)</th>
                                    <th>Signups</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr className="font-weight-bold">
                                    <td>Totals</td>
                                    <td>{Math.round(totals.count)}</td>
                                </tr>
                                {statistics.map((stat, index) => {
                                    const startDate = getDateOfISOWeek(stat.week, stat.year);
                                    const endDate = new Date(startDate);
                                    endDate.setDate(startDate.getDate() + 6);

                                    const startDateStr = startDate.toLocaleDateString('default', { month: 'short', day: 'numeric' });
                                    const endDateStr = endDate.toLocaleDateString('default', { month: 'short', day: 'numeric', year: 'numeric' });

                                    return (
                                        <tr key={index}>
                                            <td>{`${startDateStr} - ${endDateStr}`}</td>
                                            <td>{Math.round(stat.count)}</td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                        </div>
                    </>
                )
            )}
            <style jsx>{`
                .period-link {
                    color: goldenrod;
                    margin: 0 5px;
                    text-decoration: none;
                    cursor: pointer;
                }
                .period-link.active {
                    color: blue;
                    font-weight: bold;
                }
                .period-link:hover {
                    text-decoration: underline;
                }
            `}</style>
        </div>
    );
};

export default withAuth(CustomerSignupStatisticsByWeek);
