import React, {useContext} from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import AdminLogin from "./components/AdminLogin";
import Dashboard from "./components/Dashboard";
import DriverList from "./components/drivers/DriverList";
import "./App.css";
import Header from "./components/Header";
import {AuthContext} from "./components/AuthContext";
import DriverProfile from "./components/drivers/DriverProfile";
import Footer from "./components/Footer";
import PickupList from "./components/pickups/PickupList";
import PickupDetails from "./components/pickups/PickupDetails";
import UserList from "./components/users/UserList";
import UserProfile from "./components/users/UserProfile";
import Admin2FA from "./components/Admin2FA";
import MyProfile from "./components/MyProfile";
import SendNotification from "./components/drivers/SendNotification";
import SendNotificationUser from "./components/users/SendNotification";
import AdminLogs from "./components/AdminLogs";
import VehicleList from "./components/vehicles/VehicleList";
import VehicleDetails from "./components/vehicles/VehicleDetails";
import UserCreate from "./components/users/UserCreate";
import PickupCreate from "./components/pickups/PickupCreate";
import CargoInsuranceList from "./components/cargo_incurances/CargoInsuranceList";
import TruckTypePrices from "./components/truck_types/TruckTypePrices";
import TaxRateList from "./components/tax-rates/TaxRateList";
import PayoutReports from "./components/drivers/PayoutReports";
import Coworkers from "./components/coworkers/Coworkers";
import CoworkerProfile from "./components/coworkers/CoworkerProfile";
import CoworkerCreate from "./components/coworkers/CoworkerCreate";
import Permissions from "./components/coworkers/Permissions";
import Roles from "./components/coworkers/Roles";
import RoleDetails from "./components/coworkers/RoleDetails";
import PermissionDetails from "./components/coworkers/PermissionDetails";
import RoleCreate from "./components/coworkers/RoleCreate";
import PermissionCreate from "./components/coworkers/PermissionCreate";
import ContactList from "./components/contacts/ContactList";
import ContactDetails from "./components/contacts/ContactDetails";
import PromoCodeList from "./components/promo_codes/PromoCodeList";
import PromoCodeCreate from "./components/promo_codes/PromoCodeCreate";
import PromoCodeDetails from "./components/promo_codes/PromoCodeDetails";
import DriverPerformanceStatistics from "./components/drivers/DriverPerformanceStatistics";
import DeviceList from "./components/devices/DeviceList";
import UserPerformanceStatistics from "./components/users/UserPerformanceStatistics";
import Settings from "./components/settings/Settings";
import SalesReport from "./components/sales/SalesReport";
import PickupStatisticsByDay from "./components/pickups/PickupStatisticsByDay";
import DriverSignupStatisticsByWeek from "./components/drivers/DriverSignupStatisticsByWeek";
import UserSignupStatisticsByWeek from "./components/users/UserSignupStatisticsByWeek";
import FeedbackList from "./components/feedbacks/FeedbackList";
import VehicleCreate from "./components/vehicles/VehicleCreate";

const App = () => {
    const {token, hasRole, hasPermission} = useContext(AuthContext);

    return (
        <Router>
            <div className="App d-flex flex-column min-vh-100">
                <Header token={token}/>
                <div className="content">
                    <Routes>
                        <Route path="/" element={<AdminLogin/>}/>
                        <Route path="/2fa" element={<Admin2FA/>}/>
                        <Route path="/dashboard" element={<Dashboard/>}/>

                        {hasPermission('driver-view') && (
                            <>
                                <Route path="/drivers" element={<DriverList/>}/>
                                <Route path="/drivers/:driverId" element={<DriverProfile/>}/>
                                <Route path="/drivers/performance" element={<DriverPerformanceStatistics/>}/>
                                <Route path="/drivers/statistics/signups/by_week" element={<DriverSignupStatisticsByWeek/>}/>
                            </>
                        )}
                        {hasPermission('driver-update') && (
                            <>
                                <Route path="/drivers/notify-all" element={<SendNotification/>}/>
                                <Route path="/drivers/payout-reports" element={<PayoutReports/>}/>
                            </>
                        )}

                        {hasPermission('pickup-view') && (
                            <>
                                <Route path="/pickups" element={<PickupList/>}/>
                                <Route path="/pickups/:pickupId" element={<PickupDetails/>}/>
                                <Route path="/pickups/statistics/by_day" element={<PickupStatisticsByDay/>}/>
                            </>
                        )}
                        {hasPermission('pickup-update') && <Route path="/pickups/create/:userId" element={<PickupCreate/>}/>}

                        {hasPermission('sales-view') && <Route path="/pickups/sales" element={<SalesReport/>}/>}

                        {hasPermission('user-view') && (
                            <>
                                <Route path="/users" element={<UserList/>}/>
                                <Route path="/users/:userId" element={<UserProfile/>}/>
                                <Route path="/users/performance" element={<UserPerformanceStatistics/>}/>
                                <Route path="/users/statistics/signups/by_week" element={<UserSignupStatisticsByWeek/>}/>
                            </>
                        )}
                        {hasPermission('user-update') && (
                            <>
                                <Route path="/users/create" element={<UserCreate/>}/>
                                <Route path="/users/notify-all" element={<SendNotificationUser/>}/>
                            </>
                        )}

                        <Route path="/my-profile" element={<MyProfile/>}/>

                        {hasPermission('vehicle-view') && (
                            <>
                                <Route path="/vehicles" element={<VehicleList/>}/>
                                <Route path="/vehicles/create" element={<VehicleCreate/>}/>
                                <Route path="/vehicles/:vehicleId" element={<VehicleDetails/>}/>
                            </>
                        )}

                        {hasPermission('device-view') && <Route path="/devices" element={<DeviceList/>}/>}

                        {hasPermission('admin-log-view') && <Route path="/admin-logs" element={<AdminLogs/>}/>}

                        {hasPermission('promo-code-view') && (
                            <>
                                <Route path="/promo-codes" element={<PromoCodeList/>}/>
                                <Route path="/promo-codes/:id" element={<PromoCodeDetails/>}/>
                            </>
                        )}
                        {hasPermission('promo-code-update') && <Route path="/promo-codes/create" element={<PromoCodeCreate/>}/>}

                        {hasPermission('cargo-insurance-view') && <Route path="/cargo-insurances" element={<CargoInsuranceList/>}/>}
                        {hasPermission('truck-type-view') && <Route path="/truck-types/prices" element={<TruckTypePrices/>}/>}
                        {hasPermission('tax-rate-view') && <Route path="/tax-rates" element={<TaxRateList/>}/>}

                        {hasPermission('contact-view') && (
                            <>
                                <Route path="/contacts" element={<ContactList/>}/>
                                <Route path="/contacts/:contactId" element={<ContactDetails/>}/>
                            </>
                        )}

                        {hasPermission('setting-view') && <Route path="/settings" element={<Settings/>}/>}
                        {hasPermission('feedback-view') && <Route path="/feedbacks" element={<FeedbackList/>}/>}

                        {hasRole('super-admin') && (
                            <>
                                <Route path="/coworkers" element={<Coworkers/>}/>
                                <Route path="/coworkers/:coworkerId" element={<CoworkerProfile/>}/>
                                <Route path="/coworkers/create" element={<CoworkerCreate/>}/>
                                <Route path='/coworkers/roles' element={<Roles/>}/>
                                <Route path='/coworkers/roles/:roleId' element={<RoleDetails/>}/>
                                <Route path="/coworkers/roles/create" element={< RoleCreate/>}/>
                                <Route path='/coworkers/permissions' element={<Permissions/>}/>
                                <Route path='/coworkers/permissions/:permissionId' element={<PermissionDetails/>}/>
                                <Route path="/coworkers/permissions/create" element={<PermissionCreate/>}/>
                            </>
                        )}
                    </Routes>
                </div>
                <Footer/>
            </div>
        </Router>
    );
};

export default App;
