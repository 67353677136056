import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import withAuth from "../../auth";
import { API_URL } from '../../constants';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { AuthContext } from "../AuthContext";
import ReactApexChart from 'react-apexcharts';

const PickupStatisticsByDay = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [period, setPeriod] = useState(searchParams.get('period') || 'this_week');
    const [startDate, setStartDate] = useState(searchParams.get('start_date') || '');
    const [endDate, setEndDate] = useState(searchParams.get('end_date') || '');
    const [statistics, setStatistics] = useState([]);
    const [totals, setTotals] = useState({});
    const [chartData, setChartData] = useState({
        categories: [],
        series: []
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showDateInputs, setShowDateInputs] = useState(period === 'select_period');

    const navigate = useNavigate();
    const { token, setToken } = useContext(AuthContext);

    useEffect(() => {
        const newPeriod = searchParams.get('period') || 'this_week';
        const newStartDate = searchParams.get('start_date') || '';
        const newEndDate = searchParams.get('end_date') || '';
        setPeriod(newPeriod);
        setStartDate(newStartDate);
        setEndDate(newEndDate);
    }, [searchParams]);

    useEffect(() => {
        if (period !== 'select_period' || (startDate && endDate)) {
            fetchStatistics();
        }
        setShowDateInputs(period === 'select_period');
    }, [period, startDate, endDate]);

    const fetchStatistics = async () => {
        setLoading(true);
        setError(null);
        try {
            const params = {
                period: period,
            };
            if (period === 'select_period') {
                params.start_date = startDate;
                params.end_date = endDate;
            }
            const response = await axios.get(`${API_URL}/api/admin/pickups/statistics/by_day`, {
                params: params,
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            setStatistics(response.data.data);
            setTotals(response.data.totals);
            prepareChartData(response.data.data);
        } catch (error) {
            console.error("There was an error fetching the statistics!", error);
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                setToken(null);
                navigate('/');
            } else {
                setError("Failed to fetch statistics. Please try again.");
            }
        } finally {
            setLoading(false);
        }
    };

    const handleDateChange = (e) => {
        const { name, value } = e.target;
        setError(null);
        if (name === 'startDate') {
            setStartDate(value);
            setSearchParams({ period: period, start_date: value, end_date: endDate });
        } else if (name === 'endDate') {
            setEndDate(value);
            setSearchParams({ period: period, start_date: startDate, end_date: value });
        }
    };

    const prepareChartData = (data) => {
        const sortedData = [...data].sort((a, b) => {
            const dateA = new Date(a.year, a.month - 1, a.day);
            const dateB = new Date(b.year, b.month - 1, b.day);
            return dateA - dateB;
        });

        const categories = sortedData.map(stat =>
            `${stat.year}-${String(stat.month).padStart(2, '0')}-${String(stat.day).padStart(2, '0')}`
        );

        const totalPickups = sortedData.map(stat => stat.count_all);
        const completedPickups = sortedData.map(stat => stat.count_completed);
        const canceledPickups = sortedData.map(stat => stat.count_canceled);

        setChartData({
            categories: categories,
            series: [
                {
                    name: 'Total Pickups',
                    data: totalPickups
                },
                {
                    name: 'Completed',
                    data: completedPickups
                },
                {
                    name: 'Canceled',
                    data: canceledPickups
                }
            ]
        });
    };

    const chartOptions = {
        chart: {
            type: 'line',
            height: 350,
            zoom: {
                enabled: true
            },
            toolbar: {
                show: true
            }
        },
        colors: ['#007bff', '#28a745', '#dc3545'], // bg-primary, bg-success, bg-danger
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: 2,
            curve: 'straight'
        },
        xaxis: {
            categories: chartData.categories,
            labels: {
                rotate: -45,
                rotateAlways: false,
                hideOverlappingLabels: false,
                showDuplicates: false,
                trim: true,
                minHeight: undefined,
                maxHeight: 120,
            }
        },
        yaxis: {
            min: 0,
            forceNiceScale: true,
        },
        legend: {
            show: false
        }
    };

    return (
        <div className="container-fluid mt-3">
            <h2 className="text-center">Pickup Statistics by Day</h2>
            <div className="form-group text-center">
                <span>Period: </span>
                {['this_week', 'last_week', 'this_month', 'last_month', 'this_year', 'select_period'].map((timePeriod, index) => (
                    <React.Fragment key={timePeriod}>
                        <Link
                            to={`?period=${timePeriod}`}
                            className={`period-link ${period === timePeriod ? 'active' : ''}`}
                        >
                            {timePeriod
                                .replace('this_week', 'This Week')
                                .replace('last_week', 'Last Week')
                                .replace('this_month', 'This Month')
                                .replace('last_month', 'Last Month')
                                .replace('this_year', 'This Year')
                                .replace('select_period', 'Custom Range')}
                        </Link>
                        {index < 5 && <span> / </span>}
                    </React.Fragment>
                ))}
            </div>
            <div className={`collapse ${showDateInputs ? 'show' : ''}`}>
                <div className="form-group row justify-content-center">
                    <div className="col-auto">
                        <label htmlFor="startDate" className="col-form-label">Start Date:</label>
                    </div>
                    <div className="col-auto">
                        <input
                            type="date"
                            id="startDate"
                            name="startDate"
                            value={startDate}
                            onChange={handleDateChange}
                            className="form-control"
                        />
                    </div>
                    <div className="col-auto">
                        <label htmlFor="endDate" className="col-form-label">End Date:</label>
                    </div>
                    <div className="col-auto">
                        <input
                            type="date"
                            id="endDate"
                            name="endDate"
                            value={endDate}
                            onChange={handleDateChange}
                            className="form-control"
                        />
                    </div>
                </div>
            </div>
            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : error ? (
                <div className="alert alert-danger text-center" role="alert">
                    {error}
                </div>
            ) : (
                statistics.length === 0 ? (
                    <p className="text-center">No data available for the selected period.</p>
                ) : (
                    <>
                        <div className="card mt-4">
                            <div className="card-header bg-transparent pd-b-0 pd-t-20 bd-b-0 mb-3">
                                <div className="total-revenue d-flex justify-content-center mt-4">
                                    <div className="text-center mx-3">
                                        <h4>{totals.count_all}</h4>
                                        <label><span className="bg-primary px-2 py-1 rounded"/> Total</label>
                                    </div>
                                    <div className="text-center mx-3">
                                        <h4>{totals.count_completed}</h4>
                                        <label><span className="bg-success px-2 py-1 rounded"/> Completed</label>
                                    </div>
                                    <div className="text-center mx-3">
                                        <h4>{totals.count_canceled}</h4>
                                        <label><span className="bg-danger px-2 py-1 rounded"/> Canceled</label>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div id="lineChart" className="mt-4">
                                    <ReactApexChart
                                        options={chartOptions}
                                        series={chartData.series}
                                        type="line"
                                        height={350}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="table-responsive mt-4">
                            <h4 className="text-center">Daily Pickup Statistics</h4>
                            <table className="table table-sm table-striped text-center">
                                <thead>
                                <tr>
                                    <th>Date</th>
                                    <th className="text-primary">Total Pickups</th>
                                    <th className="text-success">Completed</th>
                                    <th className="text-danger">Canceled</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr className="font-weight-bold">
                                    <td>Totals</td>
                                    <td className="text-primary">{totals.count_all}</td>
                                    <td className="text-success">{totals.count_completed}</td>
                                    <td className="text-danger">{totals.count_canceled}</td>
                                </tr>
                                {/* Render the rows with month separators */}
                                {(() => {
                                    let previousMonth = null;
                                    const rows = [];
                                    for (let i = 0; i < statistics.length; i++) {
                                        const stat = statistics[i];
                                        const currentMonth = `${stat.year}-${String(stat.month).padStart(2, '0')}`;
                                        const date = `${currentMonth}-${String(stat.day).padStart(2, '0')}`;

                                        if (previousMonth !== currentMonth) {
                                            rows.push(
                                                <tr key={`month-${currentMonth}`} className="table-secondary">
                                                    <td colSpan="4" className="font-weight-bold">
                                                        {new Date(stat.year, stat.month - 1).toLocaleString('default', { month: 'long', year: 'numeric' })}
                                                    </td>
                                                </tr>
                                            );
                                            previousMonth = currentMonth;
                                        }

                                        rows.push(
                                            <tr key={i}>
                                                <td>{date}</td>
                                                <td className="text-primary">{stat.count_all}</td>
                                                <td className="text-success">{stat.count_completed}</td>
                                                <td className="text-danger">{stat.count_canceled}</td>
                                            </tr>
                                        );
                                    }
                                    return rows;
                                })()}
                                </tbody>
                            </table>
                        </div>
                    </>
                )
            )}
            <style jsx>{`
                .period-link {
                    color: goldenrod;
                    margin: 0 5px;
                    text-decoration: none;
                    cursor: pointer;
                }
                .period-link.active {
                    color: blue;
                    font-weight: bold;
                }
                .period-link:hover {
                    text-decoration: underline;
                }
            `}</style>
        </div>
    );
};

export default withAuth(PickupStatisticsByDay);
