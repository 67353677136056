import React, { useState, useEffect } from 'react';

const Thumbnail = ({ fileUrl, alt = '' }) => {
    const [isPdf, setIsPdf] = useState(false);

    useEffect(() => {
        if (fileUrl && fileUrl.endsWith('.pdf')) {
            setIsPdf(true);
        } else {
            setIsPdf(false);
        }
    }, [fileUrl]);

    if (isPdf) {
        // <iframe src={"https://docs.google.com/viewer?url=" + encodeURIComponent(fileUrl) + "&embedded=true"} style={{border: "none", width: "100%", minHeight: "300px"}}/>
        return <embed src={fileUrl + "#navpanes=0"} type="application/pdf" style={{width: "100%", minHeight: "300px"}}/>
    } else {
        return <img src={fileUrl} alt={alt} style={{ width: "auto", height: "300px"}} />;
    }
};

export default Thumbnail;
