import React, { createContext, useEffect, useState } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(localStorage.getItem('authToken'));
    const [admin, setAdmin] = useState(() => {
        // Get admin data from local storage or set to null if not available
        const savedAdmin = localStorage.getItem('adminData');
        return savedAdmin ? JSON.parse(savedAdmin) : null;
    });

    useEffect(() => {
        setToken(localStorage.getItem('authToken'));
    }, []);

    // Sync admin data if it's expected to change
    useEffect(() => {
        if (admin) localStorage.setItem('adminData', JSON.stringify(admin));
        else localStorage.removeItem('adminData');
    }, [admin]);

    const hasRole = (role) => {
        if (!admin || !admin.roles) return false;
        return admin.roles.some(p => p.name === role);
    };

    const hasPermission = (permission) => {
        if (!admin || !admin.roles) return false;
        for (const role of admin.roles) {
            if (role.permissions.some(p => p.name === permission)) {
                return true;
            }
        }
        return false;
    };

    return (
        <AuthContext.Provider value={{ token, setToken, admin, setAdmin, hasRole, hasPermission }}>
            {children}
        </AuthContext.Provider>
    );
};
