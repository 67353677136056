import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import withAuth from '../../auth';
import Pagination from '../Pagination';
import moment from 'moment';
import { API_URL } from '../../constants';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const FeedbackList = () => {
    const [feedbacks, setFeedbacks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [filters, setFilters] = useState({
        id: '',
        pickup_id: '',
        user_id: '',
        driver_id: '',
        rate: '',
        feedback: '',
        rating_by: '',
        created_at_start_date: '',
        created_at_end_date: '',
    });
    const [totals, setTotals] = useState({
        total_unique_pickups: 0,
        total_unique_users: 0,
        total_unique_drivers: 0,
        total_ratings_of_5: 0,
        average_rating: 0,
    });
    const [showModal, setShowModal] = useState(false);
    const [modalFeedback, setModalFeedback] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const { token, setToken } = useContext(AuthContext);

    useEffect(() => {
        // Parse query parameters from URL
        const queryParams = new URLSearchParams(location.search);
        const newFilters = { ...filters };
        let page = currentPage;

        queryParams.forEach((value, key) => {
            if (key === 'page') {
                page = parseInt(value);
                setCurrentPage(page);
            } else if (key in filters) {
                newFilters[key] = value;
            }
        });

        setFilters(newFilters);
        fetchFeedbacks(newFilters, page);
        // eslint-disable-next-line
    }, [location.search]);

    const fetchFeedbacks = async (newFilters = filters, page = currentPage) => {
        setLoading(true);
        try {
            const params = {
                page: page,
                ...newFilters,
            };

            // Remove empty strings from parameters
            const filteredParams = Object.fromEntries(
                Object.entries(params).filter(([key, value]) => value !== '')
            );

            const response = await axios.get(`${API_URL}/api/admin/feedbacks`, {
                params: filteredParams,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            setFeedbacks(response.data.data);
            setTotalPages(response.data.last_page);

            // Update totals
            if (response.data.totals) {
                setTotals(response.data.totals);
            }
        } catch (error) {
            console.error('There was an error fetching the feedbacks!', error);
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                setToken(null);
                navigate('/');
            }
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        updateURL(filters, page);
    };

    const handleFilterChange = (e, column) => {
        const newFilters = {
            ...filters,
            [column]: e.target.value,
        };
        setFilters(newFilters);
    };

    const handleSearchSubmit = () => {
        setCurrentPage(1);
        updateURL(filters, 1);
    };

    const handleDropdownChange = (e, column) => {
        const newFilters = {
            ...filters,
            [column]: e.target.value,
        };
        setFilters(newFilters);
        setCurrentPage(1);
        updateURL(newFilters, 1);
    };

    const updateURL = (newFilters, page) => {
        const params = new URLSearchParams();
        for (const key in newFilters) {
            if (newFilters[key] !== '') {
                params.append(key, newFilters[key]);
            }
        }
        if (page && page > 1) {
            params.append('page', page);
        }
        navigate({
            pathname: location.pathname,
            search: params.toString(),
        });
    };

    const handleShowModal = (feedbackText) => {
        setModalFeedback(feedbackText);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModalFeedback('');
    };

    return (
        <div className="container-fluid mt-3">
            <h2 className="text-center">Feedback List</h2>
            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : (
                <>
                    <div className="table-responsive">
                        <table className="table table-sm text-center text-nowrap">
                            <thead>
                            <tr>
                                <th>ID</th>
                                <th>Pickup</th>
                                <th>Customer</th>
                                <th>Driver</th>
                                <th>Rating By</th>
                                <th>Rate</th>
                                <th>Feedback</th>
                                <th>Created At</th>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Totals</strong>
                                </td>
                                <td>
                                    <b>{totals.total_unique_pickups}</b>
                                </td>
                                <td>
                                    <b>{totals.total_unique_users}</b>
                                </td>
                                <td>
                                    <b>{totals.total_unique_drivers}</b>
                                </td>
                                <td></td>
                                <td>
                                    <b>5s:</b> {totals.total_ratings_of_5} | <b>Avg:</b> {totals.average_rating}
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                {/* ID */}
                                <td>
                                    <div className="input-group">
                                        <input
                                            className="form-control form-control-sm text-center"
                                            type="text"
                                            inputMode="numeric"
                                            value={filters.id}
                                            placeholder="ID"
                                            onChange={(e) => handleFilterChange(e, 'id')}
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter') handleSearchSubmit();
                                            }}
                                        />
                                        <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                            <i className="fa fa-search" />
                                        </button>
                                    </div>
                                </td>
                                {/* Pickup ID */}
                                <td>
                                    <div className="input-group">
                                        <input
                                            className="form-control form-control-sm text-center"
                                            type="text"
                                            inputMode="numeric"
                                            value={filters.pickup_id}
                                            placeholder="Pickup ID"
                                            onChange={(e) => handleFilterChange(e, 'pickup_id')}
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter') handleSearchSubmit();
                                            }}
                                        />
                                        <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                            <i className="fa fa-search" />
                                        </button>
                                    </div>
                                </td>
                                {/* User ID */}
                                <td>
                                    <div className="input-group">
                                        <input
                                            className="form-control form-control-sm text-center"
                                            type="text"
                                            inputMode="numeric"
                                            value={filters.user_id}
                                            placeholder="User ID"
                                            onChange={(e) => handleFilterChange(e, 'user_id')}
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter') handleSearchSubmit();
                                            }}
                                        />
                                        <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                            <i className="fa fa-search" />
                                        </button>
                                    </div>
                                </td>
                                {/* Driver ID */}
                                <td>
                                    <div className="input-group">
                                        <input
                                            className="form-control form-control-sm text-center"
                                            type="text"
                                            inputMode="numeric"
                                            value={filters.driver_id}
                                            placeholder="Driver ID"
                                            onChange={(e) => handleFilterChange(e, 'driver_id')}
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter') handleSearchSubmit();
                                            }}
                                        />
                                        <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                            <i className="fa fa-search" />
                                        </button>
                                    </div>
                                </td>
                                {/* Rating By */}
                                <td>
                                    <div className="input-group">
                                        <select
                                            className="form-control form-control-sm text-center"
                                            value={filters.rating_by}
                                            onChange={(e) => handleDropdownChange(e, 'rating_by')}
                                        >
                                            <option value="">All</option>
                                            <option value="1">Customer</option>
                                            <option value="2">Driver</option>
                                        </select>
                                    </div>
                                </td>
                                {/* Rate */}
                                <td>
                                    <div className="input-group">
                                        <select
                                            className="form-control form-control-sm text-center"
                                            value={filters.rate}
                                            onChange={(e) => handleDropdownChange(e, 'rate')}
                                        >
                                            <option value="">All</option>
                                            {[1, 2, 3, 4, 5].map((rate) => (
                                                <option key={rate} value={rate}>
                                                    {rate}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </td>
                                {/* Feedback */}
                                <td>
                                    <div className="input-group">
                                        <input
                                            className="form-control form-control-sm text-center"
                                            type="text"
                                            value={filters.feedback}
                                            placeholder="Feedback"
                                            onChange={(e) => handleFilterChange(e, 'feedback')}
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter') handleSearchSubmit();
                                            }}
                                        />
                                        <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                            <i className="fa fa-search" />
                                        </button>
                                    </div>
                                </td>
                                {/* Created At */}
                                <td>
                                    <div className="input-group justify-content-center">
                                        <input
                                            className="form-control form-control-sm text-center"
                                            type="date"
                                            value={filters.created_at_start_date}
                                            onChange={(e) => handleFilterChange(e, 'created_at_start_date')}
                                            style={{ maxWidth: '130px' }}
                                        />
                                        <input
                                            className="form-control form-control-sm text-center"
                                            type="date"
                                            value={filters.created_at_end_date}
                                            onChange={(e) => handleFilterChange(e, 'created_at_end_date')}
                                            style={{ maxWidth: '130px' }}
                                        />
                                        <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                            <i className="fa fa-search" />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            </thead>
                            <tbody className="text-wrap">
                            {feedbacks.length === 0 ? (
                                <tr>
                                    <td colSpan="8">No feedbacks found.</td>
                                </tr>
                            ) : (
                                feedbacks.map((feedback) => (
                                    <tr key={feedback.id}
                                        className={
                                            feedback.rating_by === 1
                                                ? 'customer-to-driver'
                                                : 'driver-to-customer'
                                        }
                                    >
                                        <td>{feedback.id}</td>
                                        <td>
                                            <Link to={`/pickups/${feedback.pickup_id}`}>{feedback.pickup_id}</Link>
                                        </td>
                                        <td>
                                            {feedback.user ? (
                                                <>
                                                    <Link to={`/users/${feedback.user_id}`}>{feedback.user_id}</Link>
                                                    <br />
                                                    {feedback.user.name}
                                                </>
                                            ) : (
                                                'N/A'
                                            )}
                                        </td>
                                        <td>
                                            {feedback.driver ? (
                                                <>
                                                    <Link to={`/drivers/${feedback.driver_id}`}>{feedback.driver_id}</Link>
                                                    <br />
                                                    {feedback.driver.name}
                                                </>
                                            ) : (
                                                'N/A'
                                            )}
                                        </td>
                                        <td>{feedback.rating_by === 1 ? 'Customer' : 'Driver'}</td>
                                        <td>{feedback.rate}</td>
                                        <td>
                                            {feedback.feedback ? (
                                                <span
                                                    style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                                                    onClick={() => handleShowModal(feedback.feedback)}
                                                >
                                                        {feedback.feedback.length > 20
                                                            ? feedback.feedback.substring(0, 20) + '...'
                                                            : feedback.feedback}
                                                    </span>
                                            ) : (
                                                ''
                                            )}
                                        </td>
                                        <td>{moment(feedback.created_at).format('YYYY-MM-DD HH:mm')}</td>
                                    </tr>
                                ))
                            )}
                            </tbody>
                        </table>
                        {totalPages > 1 && currentPage <= totalPages && (
                            <Pagination currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />
                        )}
                    </div>

                    {/* Feedback Modal */}
                    <Modal show={showModal} onHide={handleCloseModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Feedback Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>{modalFeedback}</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseModal}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            )}
            <style jsx>{`
                .customer-to-driver {
                    background-color: inherit;
                }
                .driver-to-customer {
                    background-color: rgba(0, 0, 0, .05);
                }
            `}</style>
        </div>
    );
};

export default withAuth(FeedbackList);
