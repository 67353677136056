import React, { useState, useContext, useEffect, useRef } from 'react';
import axios from 'axios';
import { API_URL } from '../../constants';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import withAuth from '../../auth';
import Spinner from 'react-bootstrap/Spinner';
import NPPlacesInput from '../google_map/NPPlacesInput';
import NPMapViewDirections from '../google_map/NPMapViewDirections';
import { GoogleMapsProvider } from '../google_map/GoogleMapsContext';
import InputMask from "react-input-mask";

const PickupCreate = () => {
    const { userId } = useParams();
    const [user, setUser] = useState(null);
    const [routes, setRoutes] = useState([
        { id: 1, origin: {}, dropoff: {}, originUseMyself: false, dropoffUseMyself: false },
    ]);
    const [distance, setDistance] = useState(0);

    const [additionalInfo, setAdditionalInfo] = useState("");
    const additionalInfoRef = useRef(null);

    const [truckType, setTruckType] = useState(1);
    const [cargoInsurance, setCargoInsurance] = useState(6);
    const [items, setItems] = useState([]);
    const [pickup, setPickup] = useState({ origins: [], dropoffs: [] });
    const [optimizedWaypoints, setOptimizedWaypoints] = useState([]);
    const [isScheduled, setIsScheduled] = useState(false);
    const [scheduledPickupTime, setScheduledPickupTime] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [error, setError] = useState('');

    const { token, setToken } = useContext(AuthContext);
    const navigate = useNavigate();
    const itemIdCounter = useRef(0);
    const itemDetailsRefs = useRef({});

    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(today.getDate()).padStart(2, '0');
    const minDateTime = `${year}-${month}-${day}T00:00`;

    const [priceEstimate, setPriceEstimate] = useState(null);
    const [isEstimating, setIsEstimating] = useState(false);
    const [estimateError, setEstimateError] = useState('');

    const [itemSuggestions, setItemSuggestions] = useState([]);
    const [activeItemId, setActiveItemId] = useState(null);
    const searchTimeoutRef = useRef(null);
    const [photoFetchLoadingItemId, setPhotoFetchLoadingItemId] = useState(null);

    useEffect(() => {
        // Fetch the details of the user
        axios.get(`${API_URL}/api/admin/users/${userId}`, {
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((response) => {
                setUser(response.data.data);
            })
            .catch((error) => {
                console.error('There was an error fetching the user details!', error);
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('authToken');
                    setToken(null);
                    navigate('/');
                }
            });
    }, [userId, token]);

    const handlePickupSelect = (id, address, location) => {
        const newRoutes = routes.map((route) =>
            route.id === id
                ? {
                    ...route,
                    origin: {
                        ...route.origin,
                        address,
                        latitude: location.lat,
                        longitude: location.lng,
                    },
                }
                : route
        );
        setRoutes(newRoutes);
        setPickup((prev) => ({
            ...prev,
            origins: newRoutes.map((route) => route.origin),
        }));
    };

    const handleDropoffSelect = (id, address, location) => {
        const newRoutes = routes.map((route) =>
            route.id === id
                ? {
                    ...route,
                    dropoff: {
                        ...route.dropoff,
                        address,
                        latitude: location.lat,
                        longitude: location.lng,
                    },
                }
                : route
        );
        setRoutes(newRoutes);
        setPickup((prev) => ({
            ...prev,
            dropoffs: newRoutes.map((route) => route.dropoff),
        }));
    };

    const handleAddRoute = () => {
        const newRouteId = routes.length > 0 ? routes[routes.length - 1].id + 1 : 1;
        setRoutes([
            ...routes,
            { id: newRouteId, origin: {}, dropoff: {}, originUseMyself: false, dropoffUseMyself: false },
        ]);
    };

    const handleRemoveRoute = (id) => {
        const newRoutes = routes.filter((route) => route.id !== id);
        setRoutes(newRoutes);

        // Remove items assigned to the removed route
        const newItems = items.filter((item) => item.routeId !== id);

        setItems(newItems);
        setPickup((prev) => ({
            ...prev,
            origins: newRoutes.map((route) => route.origin),
            dropoffs: newRoutes.map((route) => route.dropoff),
        }));
    };

    const handleRouteChange = (id, field, value, isOrigin = true) => {
        const newRoutes = routes.map((route) =>
            route.id === id
                ? {
                    ...route,
                    [isOrigin ? 'origin' : 'dropoff']: {
                        ...route[isOrigin ? 'origin' : 'dropoff'],
                        [field]: value,
                    },
                }
                : route
        );
        setRoutes(newRoutes);
        setPickup((prev) => ({
            ...prev,
            origins: newRoutes.map((route) => route.origin),
            dropoffs: newRoutes.map((route) => route.dropoff),
        }));
    };

    const handleWaypointsOptimized = (waypoints) => {
        setOptimizedWaypoints(waypoints);
    };

    useEffect(() => {
        if (routes.length > 0 && items.length === 0) {
            setItems([
                {
                    id: itemIdCounter.current++,
                    item_name: "",
                    weight: "0-200Lbs",
                    size: "Small",
                    details: "",
                    routeId: routes[0].id,
                    photo: null,
                },
            ]);
        }
    }, [routes]);

    const handleAddItem = () => {
        if (routes.length > 0) {
            setItems([
                ...items,
                {
                    id: itemIdCounter.current++,
                    item_name: '',
                    weight: '0-200Lbs',
                    size: 'Small',
                    details: '',
                    routeId: routes[0].id,
                    photo: null,
                },
            ]);
        } else {
            alert('Please add a route before adding items.');
        }
    };

    const handleRemoveItem = (id) => {
        setItems((prevItems) => prevItems.filter((item) => item.id !== id));
        delete itemDetailsRefs.current[id];
    };

    const handleItemChange = (id, field, value) => {
        setItems((prevItems) =>
            prevItems.map((item) => (item.id === id ? { ...item, [field]: value } : item))
        );
    };

    const handleItemRouteChange = (id, newRouteId) => {
        setItems((prevItems) =>
            prevItems.map((item) => (item.id === id ? { ...item, routeId: newRouteId } : item))
        );
    };

    const handleFileChange = (id, file) => {
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (e) => {
            setItems((prevItems) =>
                prevItems.map((item) =>
                    item.id === id
                        ? {
                            ...item,
                            photo: file,
                            photoUrl: e.target.result, // base64 data URL
                        }
                        : item
                )
            );
        };
        reader.readAsDataURL(file);
    };

    const handleUseMyselfChange = (id, isOrigin, useMyself) => {
        setRoutes((prevRoutes) =>
            prevRoutes.map((route) => {
                if (route.id === id) {
                    const updatedRoute = { ...route };
                    if (isOrigin) {
                        updatedRoute.originUseMyself = useMyself;
                        if (useMyself) {
                            updatedRoute.origin.contact_name = `${user.first_name} ${user.last_name}`;
                            updatedRoute.origin.contact_phone = user.phone_num;
                        } else {
                            updatedRoute.origin.contact_name = '';
                            updatedRoute.origin.contact_phone = '';
                        }
                    } else {
                        updatedRoute.dropoffUseMyself = useMyself;
                        if (useMyself) {
                            updatedRoute.dropoff.contact_name = `${user.first_name} ${user.last_name}`;
                            updatedRoute.dropoff.contact_phone = user.phone_num;
                        } else {
                            updatedRoute.dropoff.contact_name = '';
                            updatedRoute.dropoff.contact_phone = '';
                        }
                    }
                    return updatedRoute;
                }
                return route;
            })
        );
    };

    useEffect(() => {
        adjustTextareaHeight();
    }, [additionalInfo]);
    const adjustTextareaHeight = () => {
        if (additionalInfoRef.current) {
            additionalInfoRef.current.style.height = "auto";
            additionalInfoRef.current.style.height = `${additionalInfoRef.current.scrollHeight}px`;
        }
    };

    const adjustItemTextareaHeight = (itemId) => {
        const textarea = itemDetailsRefs.current[itemId];
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
    };

    useEffect(() => {
        items.forEach((item) => {
            adjustItemTextareaHeight(item.id);
        });
    }, [items]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isScheduled && !scheduledPickupTime) {
            setError('Please select a scheduled pickup time.');
            return;
        }
        if (isScheduled && new Date(scheduledPickupTime) < new Date()) {
            setError('Scheduled pickup time cannot be in the past.');
            return;
        }

        // Prepare items with routes
        const itemsWithRoutes = items
            .map((item) => {
                const route = routes.find((r) => r.id === item.routeId);
                if (!route) {
                    return null;
                }
                return {
                    item_name: item.item_name,
                    size: item.size,
                    weight: item.weight,
                    details: item.details,
                    origin: {
                        latitude: route.origin.latitude,
                        longitude: route.origin.longitude,
                        address: route.origin.address,
                        contact_name: route.origin.contact_name,
                        contact_phone: route.origin.contact_phone,
                    },
                    dropoff: {
                        latitude: route.dropoff.latitude,
                        longitude: route.dropoff.longitude,
                        address: route.dropoff.address,
                        contact_name: route.dropoff.contact_name,
                        contact_phone: route.dropoff.contact_phone,
                    },
                    photo: item.photo,
                    photoUrl: item.photoUrl,
                };
            })
            .filter(Boolean);

        const formData = new FormData();

        formData.append("add_info", additionalInfo || "");
        formData.append('distance', distance);
        formData.append('origin_lat', pickup.origins[0]?.latitude || '');
        formData.append('origin_long', pickup.origins[0]?.longitude || '');
        formData.append('origin_address', pickup.origins[0]?.address || '');
        formData.append(
            'dropoff_lat',
            pickup.dropoffs[pickup.dropoffs.length - 1]?.latitude || ''
        );
        formData.append(
            'dropoff_long',
            pickup.dropoffs[pickup.dropoffs.length - 1]?.longitude || ''
        );
        formData.append(
            'dropoff_address',
            pickup.dropoffs[pickup.dropoffs.length - 1]?.address || ''
        );
        formData.append('truck_type_id', truckType);
        formData.append('cargo_insurance_id', cargoInsurance);
        if (isScheduled && scheduledPickupTime) formData.append('scheduled_pickup_time', scheduledPickupTime);

        itemsWithRoutes.forEach((item, index) => {
            formData.append(`items[${index}][item_name]`, item.item_name);
            formData.append(`items[${index}][weight]`, item.weight);
            formData.append(`items[${index}][size]`, item.size);
            formData.append(`items[${index}][details]`, item.details || '');

            formData.append(`items[${index}][origin][address]`, item.origin.address || '');
            formData.append(`items[${index}][origin][latitude]`, item.origin.latitude || '');
            formData.append(`items[${index}][origin][longitude]`, item.origin.longitude || '');
            formData.append(
                `items[${index}][origin][contact_name]`,
                item.origin.contact_name || ''
            );
            formData.append(
                `items[${index}][origin][contact_phone]`,
                item.origin.contact_phone || ''
            );

            formData.append(`items[${index}][dropoff][address]`, item.dropoff.address || '');
            formData.append(
                `items[${index}][dropoff][latitude]`,
                item.dropoff.latitude || ''
            );
            formData.append(
                `items[${index}][dropoff][longitude]`,
                item.dropoff.longitude || ''
            );
            formData.append(
                `items[${index}][dropoff][contact_name]`,
                item.dropoff.contact_name || ''
            );
            formData.append(
                `items[${index}][dropoff][contact_phone]`,
                item.dropoff.contact_phone || ''
            );

            if (item.photo && item.photo instanceof File) {
                formData.append(`items[${index}][photo]`, item.photo);
            }
            else if (item.photoUrl) {
                formData.append(`items[${index}][photo_url]`, item.photoUrl);
            }
        });

        pickup.origins.forEach((origin, index) => {
            formData.append(`origins[${index}][address]`, origin.address || '');
            formData.append(`origins[${index}][latitude]`, origin.latitude || '');
            formData.append(`origins[${index}][longitude]`, origin.longitude || '');
            formData.append(`origins[${index}][contact_name]`, origin.contact_name || '');
            formData.append(`origins[${index}][contact_phone]`, origin.contact_phone || '');
        });

        pickup.dropoffs.forEach((dropoff, index) => {
            formData.append(`dropoffs[${index}][address]`, dropoff.address || '');
            formData.append(`dropoffs[${index}][latitude]`, dropoff.latitude || '');
            formData.append(`dropoffs[${index}][longitude]`, dropoff.longitude || '');
            formData.append(`dropoffs[${index}][contact_name]`, dropoff.contact_name || '');
            formData.append(`dropoffs[${index}][contact_phone]`, dropoff.contact_phone || '');
        });

        if (optimizedWaypoints && optimizedWaypoints.length > 0) {
            optimizedWaypoints.forEach((waypoint, index) => {
                formData.append(
                    `optimizedWaypoints[${index}][location][lat]`,
                    waypoint.location.lat()
                );
                formData.append(
                    `optimizedWaypoints[${index}][location][lng]`,
                    waypoint.location.lng()
                );
            });
        }

        setError('');
        setIsSubmitted(true);
        try {
            const response = await axios.post(
                `${API_URL}/api/admin/pickups/create/${userId}`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response?.data?.pickup?.id) {
                navigate(`/pickups/${response.data.pickup.id}`);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                setToken(null);
                navigate('/');
            }
            if (error?.response?.data?.errors) {
                const errors = error.response.data.errors;
                const errorMessages = Object.keys(errors)
                    .map((key) => errors[key].join(' '))
                    .join(' ');
                setError(errorMessages);
            } else if (error?.response?.data?.message) {
                setError(error.response.data.message);
            } else {
                setError('An error occurred. Please try again later.');
            }
        } finally {
            setIsSubmitted(false);
        }
    };

    const lastDropoff = pickup.dropoffs[pickup.dropoffs.length - 1];
    const lastDropoffLat = lastDropoff?.latitude;
    const lastDropoffLng = lastDropoff?.longitude;

    useEffect(() => {
        if (distance > 0 && truckType && cargoInsurance && lastDropoffLat && lastDropoffLng) {
            const requestData = {
                distance,
                truck_type_id: truckType,
                cargo_insurance_id: cargoInsurance,
                dropoff_lat: lastDropoffLat,
                dropoff_long: lastDropoffLng,
            };
            if (isScheduled && scheduledPickupTime) requestData.scheduled_pickup_time = scheduledPickupTime;

            setIsEstimating(true);
            setEstimateError('');
            axios.post(`${API_URL}/api/admin/pickups/price-preview`, requestData, {
                headers: { Authorization: `Bearer ${token}` },
            })
                .then((response) => {
                    setPriceEstimate(response.data);
                })
                .catch((error) => {
                    console.error('Error fetching price estimate:', error);
                    setEstimateError('Failed to fetch price estimate.');
                    if (error.response && error.response.status === 401) {
                        localStorage.removeItem('authToken');
                        setToken(null);
                        navigate('/');
                    }
                })
                .finally(() => {
                    setIsEstimating(false);
                });
        } else {
            setPriceEstimate(null);
        }
    }, [
        distance,
        truckType,
        cargoInsurance,
        lastDropoff?.latitude,
        lastDropoff?.longitude,
        isScheduled,
        scheduledPickupTime,
        token,
    ]);

    const handleItemNameChange = (itemId, search_name) => {
        setItems((prevItems) =>
            prevItems.map((item) =>
                item.id === itemId ? { ...item, item_name: search_name } : item
            )
        );

        setActiveItemId(itemId);

        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }

        searchTimeoutRef.current = setTimeout(async () => {
            if (search_name.length < 1) {
                setItemSuggestions([]);
                return;
            }

            try {
                const response = await axios.get(
                    `${API_URL}/api/admin/pickups/items/suggestions`,
                    {
                        params: { query: search_name },
                        headers: { Authorization: `Bearer ${token}` },
                    }
                );
                setItemSuggestions(response.data);
            } catch (error) {
                console.error('Error fetching item suggestions', error);
                setItemSuggestions([]);
            }
        }, 300);
    };

    const handleSuggestionSelect = async (itemId, suggestion) => {
        setPhotoFetchLoadingItemId(itemId);

        setItems((prevItems) =>
            prevItems.map((item) => {
                if (item.id === itemId) {
                    return {
                        ...item,
                        item_name: suggestion.item_name,
                        weight: suggestion.weight || item.weight,
                        details: suggestion.details || item.details,
                        size: suggestion.size || item.size,
                        photo: null,
                        photoUrl: suggestion.photo,
                    };
                }
                return item;
            })
        );

        setItemSuggestions([]);
        setActiveItemId(null);
        setPhotoFetchLoadingItemId(null);
    };

    return (
        <GoogleMapsProvider>
            <div className="container-fluid mt-5">
                <h2 className="text-center">
                    Create Pickup for
                    <Link to={`/users/${userId}`}>
                        {' '}
                        #{userId}{' '}
                        {user
                            ? user.company
                                ? user.company.business_name
                                : user.full_name
                            : ''}
                    </Link>
                </h2>
                {error && <div className="alert alert-danger">{error}</div>}
                <div className="row">
                    <div className="col-xl-6">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={handleSubmit}   onKeyDown={(e) => {
                                    if (e.key === 'Enter' && e.target.tagName !== 'TEXTAREA') {
                                        e.preventDefault();
                                    }
                                }}>
                                    {routes.map((route, index) => (
                                        <div key={route.id} className="mb-3">
                                            <h5 className="d-flex justify-content-between">
                                                <span>Route {route.id}</span>
                                                {routes.length > 1 && (
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm btn-outline-danger ml-2 px-2 py-1"
                                                        onClick={() => handleRemoveRoute(route.id)}
                                                    >
                                                        <i className="fa fa-times" />
                                                    </button>
                                                )}
                                            </h5>
                                            <div className="mb-3">
                                                <NPPlacesInput
                                                    placeholder="Pickup Address"
                                                    value={route.origin.address || ''}
                                                    onResultSelected={(address, location) =>
                                                        handlePickupSelect(route.id, address, location)
                                                    }
                                                    uniqueKey={`origin_address_${route.id}`}
                                                />

                                                <div className="d-flex mx-2  mt-3 mb-1">
                                                    <div>Contact person for pickup</div>
                                                    <div className="px-4 mx-1">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id={`originUseMyself-${route.id}`}
                                                            checked={route.originUseMyself || false}
                                                            onChange={(e) =>
                                                                handleUseMyselfChange(
                                                                    route.id,
                                                                    true,
                                                                    e.target.checked
                                                                )
                                                            }
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    handleUseMyselfChange(route.id, true, !route.originUseMyself);
                                                                }
                                                            }}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor={`originUseMyself-${route.id}`}
                                                        >
                                                            Customer{' '}
                                                            {user?.first_name &&
                                                            `(${user.first_name} ${user.last_name})`}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="d-flex gap-2">
                                                    <input
                                                        type="text"
                                                        value={route.origin.contact_name || ''}
                                                        onChange={(e) =>
                                                            handleRouteChange(
                                                                route.id,
                                                                'contact_name',
                                                                e.target.value,
                                                                true
                                                            )
                                                        }
                                                        placeholder="Contact name"
                                                        className="form-control w-50 mr-2"
                                                        // readOnly={route.originUseMyself}
                                                    />

                                                    <InputMask
                                                        className="form-control w-50"
                                                        mask="+1 (999) 999-9999"
                                                        value={route.origin.contact_phone || ""}
                                                        placeholder="Contact phone"
                                                        onChange={(e) => {
                                                            let value = e.target.value;
                                                            value = value.replace(/[^0-9]/g, "");

                                                            if (!value.startsWith("1")) {
                                                                value = "1" + value;
                                                            }

                                                            handleRouteChange(
                                                                route.id,
                                                                "contact_phone",
                                                                `+${value}`,
                                                                true
                                                            );
                                                        }}
                                                        onBlur={(e) => {
                                                            if (!e.target.value || e.target.value === "+1") {
                                                                handleRouteChange(route.id, "contact_phone", "+1", true);
                                                            }
                                                        }}
                                                        // readOnly={route.originUseMyself}
                                                    />
                                                </div>
                                            </div>

                                            <NPPlacesInput
                                                placeholder="Drop-off Address"
                                                value={route.dropoff.address || ''}
                                                onResultSelected={(address, location) =>
                                                    handleDropoffSelect(route.id, address, location)
                                                }
                                                uniqueKey={`dropoff_address_${route.id}`}
                                            />

                                            <div className="d-flex mx-2  mt-3 mb-1">
                                                <div>Contact person for drop-off</div>
                                                <div className="px-4 mx-1">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id={`dropoffUseMyself-${route.id}`}
                                                        checked={route.dropoffUseMyself || false}
                                                        onChange={(e) =>
                                                            handleUseMyselfChange(
                                                                route.id,
                                                                false,
                                                                e.target.checked
                                                            )
                                                        }
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                handleUseMyselfChange(route.id, false, !route.dropoffUseMyself);
                                                            }
                                                        }}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor={`dropoffUseMyself-${route.id}`}
                                                    >
                                                        Customer{' '}
                                                        {user?.first_name &&
                                                        `(${user.first_name} ${user.last_name})`}
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="d-flex gap-2">
                                                <input
                                                    type="text"
                                                    value={route.dropoff.contact_name || ''}
                                                    onChange={(e) =>
                                                        handleRouteChange(
                                                            route.id,
                                                            'contact_name',
                                                            e.target.value,
                                                            false
                                                        )
                                                    }
                                                    placeholder="Contact name"
                                                    className="form-control w-50 mr-1"
                                                    // readOnly={route.dropoffUseMyself}
                                                />

                                                <InputMask
                                                    className="form-control w-50"
                                                    mask="+9 (999) 999-9999"
                                                    value={route.dropoff.contact_phone || ""}
                                                    placeholder="Contact phone"
                                                    onChange={(e) => {
                                                        let value = e.target.value;
                                                        value = value.replace(/[^0-9]/g, "");

                                                        if (!value.startsWith("1")) {
                                                            value = "1" + value;
                                                        }

                                                        handleRouteChange(
                                                            route.id,
                                                            "contact_phone",
                                                            `+${value}`,
                                                            false
                                                        );
                                                    }}
                                                    onBlur={(e) => {
                                                        if (!e.target.value || e.target.value === "+1") {
                                                            handleRouteChange(route.id, "contact_phone", "+1", false);
                                                        }
                                                    }}
                                                    // readOnly={route.dropoffUseMyself}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                    <button
                                        type="button"
                                        className="btn btn-secondary mb-2"
                                        onClick={handleAddRoute}
                                    >
                                        Add Route
                                    </button>
                                    <div className="form-row mb-1">
                                        <label className="col-sm-3 col-form-label">Truck Type:</label>
                                        <div className="col-sm-9">
                                            <select
                                                className="custom-select"
                                                value={truckType}
                                                onChange={(e) => setTruckType(e.target.value)}
                                            >
                                                <option>Select Truck Type</option>
                                                <option value="1">Pickup truck</option>
                                                <option value="2">Flatbed truck</option>
                                                <option value="3">Cube Van</option>
                                                <option value="4">Cargo Van</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-row mb-2">
                                        <label className="col-sm-3 col-form-label">
                                            Cargo Insurance:
                                        </label>
                                        <div className="col-sm-9">
                                            <select
                                                className="custom-select"
                                                value={cargoInsurance}
                                                onChange={(e) => setCargoInsurance(e.target.value)}
                                            >
                                                <option>Select Cargo Insurance</option>
                                                <option value="1">$0 - $499</option>
                                                <option value="2">$500 - $999</option>
                                                <option value="3">$1000 - $1999</option>
                                                <option value="4">$2000 - $2999</option>
                                                <option value="5">$3000 - $5000</option>
                                                <option value="6">N/A</option>
                                            </select>
                                        </div>
                                    </div>
                                    <textarea
                                        ref={additionalInfoRef}
                                        value={additionalInfo}
                                        className="form-control"
                                        name="add_info"
                                        onChange={(e) => {
                                            setAdditionalInfo(e.target.value);
                                            adjustTextareaHeight();
                                        }}
                                        placeholder="Additional Info"
                                        rows={1}
                                    />

                                    <div className="form-group form-check mt-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="scheduledPickupCheckbox"
                                            checked={isScheduled}
                                            onChange={(e) => setIsScheduled(e.target.checked)}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="scheduledPickupCheckbox"
                                        >
                                            Schedule Pickup
                                        </label>
                                    </div>

                                    {isScheduled && (
                                        <div className="form-group">
                                            <label htmlFor="scheduledPickupTime">
                                                Scheduled Pickup Time:
                                            </label>
                                            <input
                                                type="datetime-local"
                                                className="form-control"
                                                id="scheduledPickupTime"
                                                value={scheduledPickupTime}
                                                onChange={(e) => setScheduledPickupTime(e.target.value)}
                                                min={minDateTime} // Ensures the time is not in the past
                                                required
                                            />
                                        </div>
                                    )}

                                    <hr />
                                    <h5 className="text-center">Items</h5>
                                    <table className="table table-sm table-borderless">
                                            <tbody>
                                            {items &&
                                            items.map((item) => {
                                                const routeOptions = routes.map((route) => ({
                                                    value: route.id,
                                                    label: `Route ${route.id}`,
                                                }));

                                                const selectedRouteOption =
                                                    routeOptions.find(
                                                        (option) => option.value === item.routeId
                                                    ) || null;

                                                return (
                                                    <React.Fragment key={item.id}>
                                                        <tr style={{ position: 'relative' }}>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    value={item.item_name}
                                                                    onChange={(e) => handleItemNameChange(item.id, e.target.value)}
                                                                    onFocus={() => setActiveItemId(item.id)}
                                                                    onBlur={() => {
                                                                        setTimeout(() => {
                                                                            setItemSuggestions([]);
                                                                            setActiveItemId(null);
                                                                        }, 200);
                                                                    }}
                                                                    placeholder="Item Name"
                                                                    className="form-control"
                                                                />

                                                                {/* Only show if this item is active AND we have suggestions */}
                                                                {activeItemId === item.id && itemSuggestions.length > 0 && (
                                                                    <ul
                                                                        className="list-group"
                                                                        style={{
                                                                            position: 'absolute',
                                                                            top: '100%',
                                                                            left: 0,
                                                                            right: 0,
                                                                            zIndex: 999,
                                                                            maxHeight: '300px',
                                                                            overflowY: 'auto',
                                                                            background: '#fff',
                                                                            border: '1px solid #d4d4d4',
                                                                        }}
                                                                    >
                                                                        {itemSuggestions.map((sugg) => (
                                                                            <li
                                                                                key={sugg.item_name}
                                                                                className="list-group-item list-group-item-action"
                                                                                style={{ cursor: 'pointer' }}
                                                                                onMouseDown={() => handleSuggestionSelect(item.id, sugg)}
                                                                            >
                                                                                {sugg.item_name} | {sugg.weight} | {sugg.size}
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                )}
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    value={item.weight}
                                                                    onChange={(e) =>
                                                                        handleItemChange(
                                                                            item.id,
                                                                            'weight',
                                                                            e.target.value
                                                                        )
                                                                    }
                                                                    placeholder="Weight"
                                                                    className="form-control"
                                                                />
                                                            </td>
                                                            <td>
                                                                <select
                                                                    value={item.size}
                                                                    onChange={(e) =>
                                                                        handleItemChange(
                                                                            item.id,
                                                                            'size',
                                                                            e.target.value
                                                                        )
                                                                    }
                                                                    className="custom-select"
                                                                >
                                                                    <option value="">
                                                                        Select Size
                                                                    </option>
                                                                    <option value="Small">
                                                                        Small
                                                                    </option>
                                                                    <option value="Medium">
                                                                        Medium
                                                                    </option>
                                                                    <option value="Large">
                                                                        Large
                                                                    </option>
                                                                    <option value="Extra Large">
                                                                        Extra Large
                                                                    </option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select
                                                                    value={item.routeId}
                                                                    onChange={(e) =>
                                                                        handleItemRouteChange(
                                                                            item.id,
                                                                            parseInt(e.target.value)
                                                                        )
                                                                    }
                                                                    className="custom-select"
                                                                >
                                                                    {routeOptions.map((option) => (
                                                                        <option
                                                                            key={option.value}
                                                                            value={option.value}
                                                                        >
                                                                            {option.label}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </td>
                                                            <td rowSpan="2">
                                                                {items.length > 1 && (
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-sm btn-danger mt-1"
                                                                        onClick={() =>
                                                                            handleRemoveItem(
                                                                                item.id
                                                                            )
                                                                        }
                                                                    >
                                                                        <i className="fa fa-trash" />
                                                                    </button>
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="4">
                                                                <textarea
                                                                    ref={(el) => {
                                                                        itemDetailsRefs.current[item.id] = el;
                                                                    }}
                                                                    value={item.details}
                                                                    onChange={(e) => {
                                                                        handleItemChange(
                                                                            item.id,
                                                                            'details',
                                                                            e.target.value
                                                                        );
                                                                        adjustItemTextareaHeight(item.id);
                                                                    }}
                                                                    placeholder="Eg. 4' x 5' x 5' fragile"
                                                                    className="form-control"
                                                                    rows={1}
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="4">
                                                                <input
                                                                    className="form-control"
                                                                    type="file"
                                                                    id={`formFile-${item.id}`}
                                                                    onChange={(e) =>
                                                                        handleFileChange(
                                                                            item.id,
                                                                            e.target.files[0]
                                                                        )
                                                                    }
                                                                />


                                                                {(photoFetchLoadingItemId === item.id || item.photoUrl) &&
                                                                    <div style={{
                                                                        position: 'relative',
                                                                        width: '100px',
                                                                        height: '100px',
                                                                    }}>
                                                                        {photoFetchLoadingItemId === item.id && (
                                                                            <div style={{
                                                                                position: 'absolute',
                                                                                top: '50%',
                                                                                left: '50%',
                                                                                transform: 'translate(-50%, -50%)'
                                                                            }}>
                                                                                <Spinner animation="border"
                                                                                         className="text-primary"/>
                                                                            </div>
                                                                        )}

                                                                        {/* Image preview */}
                                                                        {item.photoUrl && (
                                                                            <div className="mt-2">
                                                                                <img
                                                                                    src={item.photoUrl}
                                                                                    alt="Preview"
                                                                                    style={{
                                                                                        maxWidth: '100px',
                                                                                        maxHeight: '100px',
                                                                                        objectFit: 'cover'
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        )}

                                                                    </div>
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="5">
                                                                <hr className="my-0" />
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                );
                                            })}
                                            </tbody>
                                    </table>
                                    <button
                                        type="button"
                                        className="btn btn-warning mb-2"
                                        onClick={handleAddItem}
                                    >
                                        Add Item
                                    </button>
                                    <hr />
                                    <div className="d-flex justify-content-center">
                                        <button  className="btn btn-primary">
                                            {isSubmitted ? (
                                                <>
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                    &nbsp;Creating...
                                                </>
                                            ) : (
                                                'Create'
                                            )}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="card">
                            <div className="card-body" style={{ minHeight: '400px' }}>
                                <NPMapViewDirections
                                    origins={pickup.origins}
                                    dropoffs={pickup.dropoffs}
                                    onDistanceCalculated={setDistance}
                                    onWaypointsOptimized={handleWaypointsOptimized}
                                />
                                <div className="mt-2">Distance: {distance} km</div>
                                {isEstimating &&
                                <div className="text-center mb-2">
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    &nbsp;Estimating price...
                                </div>
                                }
                                {estimateError && <div className="alert alert-danger">{estimateError}</div>}
                                {priceEstimate && (
                                    <div className="mt-2">
                                        <div className="table-responsive mb-4">
                                            <table className='table table-sm table-striped text-center mb-0'>
                                                <tbody>
                                                <tr>
                                                    <td>Pickup Fee (Delivery Cost)</td>
                                                    <td>${priceEstimate.delivery_cost}</td>
                                                </tr>
                                                <tr>
                                                    <td>Cargo Protection Pkg ({priceEstimate.cargo_insurance.insurance_range})</td>
                                                    <td>${priceEstimate.insurance_cost}</td>
                                                </tr>
                                                <tr>
                                                    <td>Cost (Pickup Fee + Cargo Protection Pkg)</td>
                                                    <td><b>${priceEstimate.total_cost}</b></td>
                                                </tr>
                                                <tr>
                                                    <td>GST {parseFloat(priceEstimate.tax_rate?.gst || 0)}%</td>
                                                    <td>${priceEstimate.gst_five_percent}</td>
                                                </tr>
                                                <tr>
                                                    <td>PST {parseFloat(priceEstimate.tax_rate?.pst || 0)}%</td>
                                                    <td>${priceEstimate.pst_seven_percent}</td>
                                                </tr>
                                                <tr>
                                                    <td>HST {parseFloat(priceEstimate.tax_rate?.hst || 0)}%</td>
                                                    <td>${priceEstimate.hst_amount}</td>
                                                </tr>
                                                <tr>
                                                    <td>QST {parseFloat(priceEstimate.tax_rate?.qst || 0)}%</td>
                                                    <td>${priceEstimate.qst_amount}</td>
                                                </tr>
                                                <tr>
                                                    <td>Total Cost</td>
                                                    <td><b>${priceEstimate.grand_total}</b></td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="table-responsive">
                                            <table className="table table-sm table-striped text-center mb-0">
                                                <tbody>
                                                <tr className="table-light"><td><h5 className="text-center">Driver Earnings</h5></td><td></td></tr>
                                                <tr>
                                                    <td>Pickup Fee (Delivery Cost)</td>
                                                    <td className="">${priceEstimate.delivery_cost}</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Link to={"/truck-types/prices#truck-type-" + truckType} target="_blank">
                                                            Driver Compensation Rate ({priceEstimate.truck_type.title})
                                                        </Link>
                                                    </td>
                                                    <td>{priceEstimate.truck_type.driver_percent}%</td>
                                                </tr>
                                                <tr>
                                                    <td>Driver Earnings</td>
                                                    <td><b>${priceEstimate.driver_earnings}</b></td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </GoogleMapsProvider>
    );
};

export default withAuth(PickupCreate);
