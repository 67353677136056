import React, { useState, useEffect, useContext, useMemo } from "react";
import axios from "axios";
import withAuth from "../../auth";
import { API_URL } from '../../constants';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { AuthContext } from "../AuthContext";

const SalesReport = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const initialPeriod = searchParams.get('period') || 'this_month';
    const initialAdminId = searchParams.get('admin_id') || '';

    const [period, setPeriod] = useState(initialPeriod);
    const [adminId, setAdminId] = useState(initialAdminId);
    const [salesData, setSalesData] = useState([]);
    const [totals, setTotals] = useState({});
    const [admins, setAdmins] = useState([]);
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const navigate = useNavigate();
    const { token, setToken } = useContext(AuthContext);

    useEffect(() => {
        setSearchParams({ period, admin_id: adminId });
        fetchSalesReport();
    }, [period, adminId]);

    const fetchSalesReport = async () => {
        setLoading(true);
        try {
            const response = await axios.post(
                `${API_URL}/api/admin/sales`,
                {
                    period: period,
                    admin_id: adminId || undefined,
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );
            setSalesData(response.data.data);
            setTotals(response.data.totals);
            setAdmins(response.data.admins);
            setStartDate(response.data.start_date);
            setEndDate(response.data.end_date);
        } catch (error) {
            console.error("There was an error fetching the sales report!", error);
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                setToken(null);
                navigate('/');
            }
        } finally {
            setLoading(false);
        }
    };

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-CA', {
            style: 'currency',
            currency: 'CAD',
        }).format(value);
    };

    const adminIdToName = useMemo(() => {
        const map = {};
        admins.forEach((admin) => {
            map[admin.id] = admin.name;
        });
        return map;
    }, [admins]);

    return (
        <div className="container-fluid mt-3">
            <h2 className="text-center mb-0">Sales Report</h2>
            <div className="text-center mb-2">
                <small>Only completed pickups </small>
                {startDate && endDate ? (
                    <small>
                        from <strong>{startDate}</strong> to <strong>{endDate}</strong>
                    </small>
                ) : (
                    <small>All dates</small>
                )}
            </div>
            <div className="form-group text-center">
                <span>Period: </span>
                {['this_month', 'last_month', 'this_quarter', 'last_quarter', 'this_year', 'last_year', 'all'].map((timePeriod, index) => (
                    <React.Fragment key={timePeriod}>
                        <Link
                            to={`?period=${timePeriod}&admin_id=${adminId}`}
                            className={`period-link ${period === timePeriod ? 'active' : ''}`}
                            onClick={() => setPeriod(timePeriod)}
                        >
                            {timePeriod.replace('_', ' ').replace(/\b\w/g, l => l.toUpperCase())}
                        </Link>
                        {index < 6 && <span> / </span>}
                    </React.Fragment>
                ))}
            </div>
            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : (
                <div className="table-responsive">
                    <table className="table table-sm table-striped text-center text-nowrap">
                        <thead>
                        <tr>
                            <th className="align-middle">Pickup ID</th>
                            <th className="align-middle">Customer</th>
                            <th className="align-middle">Salesperson</th>
                            <th className="align-middle" style={{ lineHeight: 1 }}>Total Cost<br /><small>(Gross)</small></th>
                            <th className="align-middle">Tax</th>
                            <th className="align-middle" style={{ lineHeight: 1 }}>Navi Earnings<br /><small>(Net)</small></th>
                            <th className="align-middle">Driver Earnings</th>
                            <th className="align-middle" style={{ lineHeight: 1 }}>Pickup Fee<br /><small>(Regular Delivery Cost)</small></th>
                            <th className="align-middle">Promo Discount</th>
                            <th className="align-middle" style={{ lineHeight: 1 }}>Pickup Fee<br /><small>(After discount)</small></th>
                            <th className="align-middle">Cargo Protection Pkg</th>
                            <th className="align-middle" style={{ lineHeight: 1 }}>Cost<br /><small>(Pickup Fee + Cargo Protection Pkg)</small></th>
                        </tr>
                        <tr>
                            <th className="align-middle">Totals</th>
                            <th className="align-middle"></th>
                            <th className="align-middle">
                                <div className="input-group">
                                    <select
                                        id="adminSelect"
                                        value={adminId}
                                        onChange={(e) => {
                                            setAdminId(e.target.value);
                                            navigate(`?period=${period}&admin_id=${e.target.value}`);
                                        }}
                                        className="form-control form-control-sm text-center"
                                    >
                                        <option value="">All</option>
                                        {admins.map((admin) => (
                                            <option key={admin.id} value={admin.id}>
                                                {admin.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </th>
                            <th className="align-middle">{formatCurrency(totals.total_cost)}</th>
                            <th className="align-middle">{formatCurrency(totals.tax)}</th>
                            <th className="align-middle text-success">{formatCurrency(totals.net_earnings)}</th>
                            <th className="align-middle">{formatCurrency(totals.driver_earnings)}</th>
                            <th className="align-middle">{formatCurrency(totals.regular_delivery_cost)}</th>
                            <th className="align-middle text-info">{totals.discount_amount > 0 ? '-' : ''}{formatCurrency(totals.discount_amount)}</th>
                            <th className="align-middle">{formatCurrency(totals.delivery_cost)}</th>
                            <th className="align-middle">{formatCurrency(totals.insurance_cost)}</th>
                            <th className="align-middle">{formatCurrency(totals.cost)}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {salesData.map((item) => (
                            <tr key={item.id}>
                                <td>
                                    <Link to={`/pickups/${item.id}`}>
                                        {item.id}
                                    </Link>
                                </td>
                                <td>
                                    <Link to={`/users/${item.user_id}`}>
                                        {item.company_name ? <><i className="fa fa-building-o" /> {item.company_name}</> : item.full_name}
                                    </Link>
                                </td>
                                <td>
                                    {item.admin_id ?
                                        <Link to={`/coworkers/${item.admin_id}`}>
                                            {adminIdToName[item.admin_id] || 'N/A'}
                                        </Link>
                                        : 'N/A'
                                    }
                                </td>
                                <td>{formatCurrency(item.total_cost)}</td>
                                <td>{formatCurrency(item.tax)}</td>
                                <td className="text-success">{formatCurrency(item.net_earnings)}</td>
                                <td>{formatCurrency(item.driver_earnings)}</td>
                                <td>{formatCurrency(item.regular_delivery_cost)}</td>
                                <td className="text-info">{item.discount_amount > 0 ? '-' : ''}{formatCurrency(item.discount_amount)}</td>
                                <td>{formatCurrency(item.delivery_cost)}</td>
                                <td>{formatCurrency(item.insurance_cost)}</td>
                                <td>{formatCurrency(item.cost)}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            )}
            <style jsx>{`
                .period-link {
                    color: goldenrod;
                    margin: 0 5px;
                    text-decoration: none;
                    cursor: pointer;
                }
                .period-link.active {
                    color: blue;
                    font-weight: bold;
                }
                .period-link:hover {
                    text-decoration: underline;
                }
            `}</style>
        </div>
    );
};

export default withAuth(SalesReport);
