import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { API_URL } from '../../constants';
import { AuthContext } from "../AuthContext";
import { useNavigate } from "react-router-dom";
import withAuth from "../../auth";
import Spinner from "react-bootstrap/Spinner";

const Settings = () => {
    const [settings, setSettings] = useState(null);
    const { token, setToken, hasPermission } = useContext(AuthContext);
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [successes, setSuccesses] = useState({});
    const [errors, setErrors] = useState({});
    const [isUpdating, setIsUpdating] = useState({});

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/admin/settings`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                setSettings(response.data);
            } catch (error) {
                handleError(error);
            }
        };

        fetchSettings();
    }, [token, navigate]);

    const handleError = (error) => {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('authToken');
            setToken(null);
            navigate('/');
        }
        if (error?.response?.data?.errors) {
            const errors = error.response.data.errors;
            const errorMessages = Object.keys(errors).map(key => errors[key].join(' ')).join(' ');
            setError(errorMessages);
        } else if (error?.response?.data?.message) {
            setError(error.response.data.message);
        } else {
            setError('An error occurred. Please try again later.');
        }
    };

    const handleChange = (id, value) => {
        setSettings(settings.map(setting => {
            if (setting.id === id) {
                return { ...setting, var_value: value };
            }
            return setting;
        }));
    };

    const handleSubmit = async (e, id) => {
        e.preventDefault();

        if (!window.confirm("Are you sure that you want to update the setting?")) return;

        setIsUpdating({ ...isUpdating, [id]: true });
        setSuccesses({ ...successes, [id]: '' });
        setErrors({ ...errors, [id]: '' });

        const settingToUpdate = settings.find(setting => setting.id === id);

        try {
            await axios.put(`${API_URL}/api/admin/settings/${id}`, {
                var_value: settingToUpdate.var_value
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setSuccesses({ ...successes, [id]: `Setting has been successfully updated.` });
            setErrors({ ...errors, [id]: '' });
        } catch (error) {
            handleError(error);
            if (error?.response?.data?.errors) {
                const errors = error.response.data.errors;
                const errorMessages = Object.keys(errors).map(key => errors[key].join(' ')).join(' ');
                setErrors({ ...errors, [id]: errorMessages });
            } else if (error?.response?.data?.message) {
                setErrors({ ...errors, [id]: error.response.data.message });
            } else {
                setErrors({ ...errors, [id]: 'An error occurred. Please try again later.' });
            }
        } finally {
            setIsUpdating({ ...isUpdating, [id]: false });
        }
    };

    return (
        <div className="container mt-5">
            <h1 className="text-center">Settings</h1>
            {error && <div className="alert alert-danger">{error}</div>}
            {settings ? (
                <div className="row">
                    {settings.map(setting => (
                        <div key={setting.id} className="col-md-6">
                            <div className="card mb-4">
                                <div className="card-body">
                                    <form onSubmit={(e) => handleSubmit(e, setting.id)}>
                                        <h6 className="text-center">{setting.description}</h6>
                                        <div className="form-group">
                                            <input
                                                className="form-control"
                                                value={setting.var_value}
                                                onChange={(e) => handleChange(setting.id, e.target.value)}
                                                readOnly={!hasPermission("setting-update")}
                                                title={setting.description}
                                            />
                                        </div>
                                        {successes[setting.id] && <div className="alert alert-success text-center">{successes[setting.id]}</div>}
                                        {errors[setting.id] && <div className="alert alert-danger text-center">{errors[setting.id]}</div>}
                                        {hasPermission("setting-update") && (
                                            <div className="d-flex">
                                                <button type="submit" className="btn btn-primary mx-auto" disabled={isUpdating[setting.id]}>
                                                    {isUpdating[setting.id]
                                                        ? (
                                                            <>
                                                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                                &nbsp;Updating...
                                                            </>
                                                        )
                                                        : 'Update'}
                                                </button>
                                            </div>
                                        )}
                                    </form>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
            )}
        </div>
    );
};

export default withAuth(Settings);
