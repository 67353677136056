import React, {useState, useEffect, useContext} from "react";
import axios from "axios";
import withAuth from "../../auth";
import Pagination from "../Pagination";
import moment from 'moment';
import { API_URL } from '../../constants';
import {Link, useNavigate} from "react-router-dom";
import {Breadcrumb} from "react-bootstrap";
import {AuthContext} from "../AuthContext";
import Spinner from "react-bootstrap/Spinner";
import {formatPhoneNumberWithMask} from "../../formatPhoneNumber";

const UserList = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [sortBy, setSortBy] = useState('id');
    const [sortOrder, setSortOrder] = useState('desc');
    const [filters, setFilters] = useState({});
    const navigate = useNavigate();
    const {token, setToken, hasPermission} = useContext(AuthContext);
    const [isExportCSVSubmitted, setIsExportCSVSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        fetchUsers();
    }, [currentPage, sortBy, sortOrder]);

    const fetchUsers = async (newFilters = null) => {
        setErrorMessage('');
        setLoading(true);
        try {
            const response = await axios.post(`${API_URL}/api/admin/users`,
                {
                    sort_by: sortBy,
                    sort_order: sortOrder,
                    page: currentPage,
                    ...(newFilters || filters),
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            setUsers(response.data.data);
            setTotalPages(response.data.last_page);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                setToken(null);
                navigate('/');
            }
            if (error?.response?.data?.errors) {
                const errors = error.response.data.errors;
                const errorMessages = Object.keys(errors).map(key => errors[key].join(' ')).join(' ');
                setErrorMessage(errorMessages);
            } else if (error?.response?.data?.message) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage('An error occurred. Please try again later.');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleSortChange = (column) => {
        if (sortBy === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(column);
            setSortOrder('asc');
        }
        setCurrentPage(1); // reset pagination when sort
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleFilterChange = (e, column) => {
        const newFilters = {
            ...filters,
            [column]: e.target.value,
        };

        const nonEmptyFilters = Object.keys(newFilters).reduce((acc, key) => {
            if (newFilters[key]) {
                acc[key] = newFilters[key];
            }
            return acc;
        }, {});

        setFilters(nonEmptyFilters);
    };
    const handleSearchSubmit = () => {
        setCurrentPage(1); // reset pagination when filter
        fetchUsers();
    };

    const handleDropdownChange = (e, column) => {
        const newFilters = {
            ...filters,
            [column]: e.target.value,
        };

        const nonEmptyFilters = Object.keys(newFilters).reduce((acc, key) => {
            if (newFilters[key]) {
                acc[key] = newFilters[key];
            }
            return acc;
        }, {});

        setFilters(nonEmptyFilters);
        setCurrentPage(1); // reset pagination when filter
        fetchUsers(nonEmptyFilters);
    };

    const handleExportCSV = async () => {
        setErrorMessage('');
        setIsExportCSVSubmitted(true);
        try {
            const response = await axios.get(`${API_URL}/api/admin/users/export/csv`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Navi-Customers.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                setToken(null);
                navigate('/');
            }
            if (error?.response?.data?.errors) {
                const errors = error.response.data.errors;
                const errorMessages = Object.keys(errors).map(key => errors[key].join(' ')).join(' ');
                setErrorMessage(errorMessages);
            } else if (error?.response?.data?.message) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage('An error occurred. Please try again later.');
            }
        } finally {
            setIsExportCSVSubmitted(false);
        }
    };

    return (
        <div className="container-fluid mt-3">
            <h2 className="text-center">Customer List</h2>
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : (
                <div className="table-responsive">
                    {hasPermission('user-update') && (
                        <div className="text-center pt-1 pb-3">
                            <button className="btn btn-primary" onClick={handleExportCSV}>
                                {isExportCSVSubmitted
                                    ? <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />&nbsp;Exporting...</>
                                    : <><i className="fa fa-file"/>&nbsp; Export CSV</>
                                }
                            </button>
                        </div>
                    )}
                    <table className="table table-sm table-striped text-center text-nowrap">
                        <thead>
                        <tr>
                            <th onClick={() => handleSortChange('id')}>
                                ID / Comp. <i
                                className={`fa fa-sort${sortBy === 'id' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`}/>
                            </th>
                            <th onClick={() => handleSortChange('full_name')}>
                                Full name <i
                                className={`fa fa-sort${sortBy === 'full_name' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`}/>
                            </th>
                            <th onClick={() => handleSortChange('email')}>
                                Email <i
                                className={`fa fa-sort${sortBy === 'email' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`}/>
                            </th>
                            <th onClick={() => handleSortChange('phone_num')}>
                                Phone <i
                                className={`fa fa-sort${sortBy === 'phone_num' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`}/>
                            </th>
                            <th onClick={() => handleSortChange('stripe_id')}>
                                Stripe ID <i
                                className={`fa fa-sort${sortBy === 'stripe_id' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`}/>
                            </th>
                            <th onClick={() => handleSortChange('created_at')}>
                                Created At / Login <i
                                className={`fa fa-sort${sortBy === 'created_at' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`}/>
                            </th>
                        </tr>
                        <tr>
                            {/* ID / Comp. */}
                            <td>
                                <div className="input-group">
                                    <input
                                        className="form-control form-control-sm text-center"
                                        type="text"
                                        inputMode="search"
                                        value={filters.id}
                                        placeholder="Customer ID"
                                        onChange={(e) => handleFilterChange(e, 'id')}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') handleSearchSubmit(e)
                                        }}
                                    />
                                    <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                        <i className="fa fa-search"/>
                                    </button>
                                </div>
                            </td>
                            {/* Full name */}
                            <td>
                                <div className="input-group">
                                    <input
                                        className="form-control form-control-sm text-center"
                                        type="text"
                                        inputMode="search"
                                        value={filters.full_name}
                                        placeholder="Full name"
                                        onChange={(e) => handleFilterChange(e, 'full_name')}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') handleSearchSubmit(e)
                                        }}
                                    />
                                    <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                        <i className="fa fa-search"/>
                                    </button>
                                </div>
                            </td>
                            {/* Email */}
                            <td>
                                <div className="input-group">
                                    <input
                                        className="form-control form-control-sm text-center"
                                        type="text"
                                        inputMode="search"
                                        value={filters.email}
                                        onChange={(e) => handleFilterChange(e, 'email')}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') handleSearchSubmit(e)
                                        }}
                                    />
                                    <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                        <i className="fa fa-search"/>
                                    </button>
                                </div>
                            </td>
                            {/* Phone */}
                            <td>
                                <div className="input-group">
                                    <input
                                        className="form-control form-control-sm text-center"
                                        type="text"
                                        inputMode="search"
                                        value={filters.phone_num}
                                        placeholder="204-123-4567"
                                        onChange={(e) => handleFilterChange(e, 'phone_num')}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') handleSearchSubmit(e)
                                        }}
                                    />
                                    <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                        <i className="fa fa-search"/>
                                    </button>
                                </div>
                            </td>
                            {/* Stripe ID */}
                            <td>
                                <div className="input-group">
                                    <input
                                        className="form-control form-control-sm text-center"
                                        type="text"
                                        inputMode="search"
                                        value={filters.stripe_id}
                                        placeholder="cus_adslkfjasdlfj"
                                        onChange={(e) => handleFilterChange(e, 'stripe_id')}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') handleSearchSubmit(e)
                                        }}
                                    />
                                    <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                        <i className="fa fa-search"/>
                                    </button>
                                </div>
                            </td>
                            {/* Reg. / Login */}
                            <td></td>
                        </tr>
                        </thead>
                        <tbody className="text-wrap">
                        {users.map((user) => (
                            <tr key={user.id}>
                                <td>
                                    <Link to={`/users/${user.id}`}>
                                        {user.id}
                                    </Link>
                                    {hasPermission('user-update') && (
                                        <>
                                            <br/>
                                            <Link to={`/pickups/create/${user.id}`}
                                                  className='btn btn-sm btn-outline-primary'>
                                                Create Pickup
                                            </Link>
                                        </>
                                    )}
                                    {user.company ? <><br/>{user.company.business_name}</> : ''}
                                </td>
                                <td>
                                    {user.profile_photo &&
                                    <div><img src={user.profile_photo} alt="Profile" height={50}/></div>}
                                    {user.full_name}
                                </td>
                                <td>{user.email}</td>
                                <td>{formatPhoneNumberWithMask(user.phone_num || "",
                                    "+9 999 999-9999")}</td>
                                <td>{user.stripe_id}</td>
                                <td>
                                    {moment(user.created_at).format('YYYY-MM-DD HH:mm')}
                                    <div>
                                        {user.last_login_at ? moment(user.last_login_at).format('YYYY-MM-DD HH:mm') : 'unknown'}
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    {totalPages > 1 && currentPage <= totalPages &&
                    <Pagination currentPage={currentPage} totalPages={totalPages}
                                handlePageChange={handlePageChange}/>
                    }
                </div>
            )}
        </div>
    );
};

export default withAuth(UserList);
