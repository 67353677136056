import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { API_URL } from "../../constants";
import { Link, useNavigate, useLocation } from "react-router-dom";
import withAuth from "../../auth";
import { Breadcrumb } from "react-bootstrap";
import { AuthContext } from "../AuthContext";
import Spinner from "react-bootstrap/Spinner";

const VehicleCreate = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { token, setToken } = useContext(AuthContext);

    const [error, setError] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [formData, setFormData] = useState({
        year: "",
        make: "",
        model: "",
        color: "",
        box_or_deck_length: "",
        cargo_insurance: "",
        plate_number: "",
        truck_type_id: "",
        driver_id: location.state?.driverId || "", // Pre-fill Driver ID if passed via state
    });

    const [driverInfo, setDriverInfo] = useState(null);

    // Fetch driver details if driver_id is provided
    useEffect(() => {
        if (formData.driver_id) {
            axios.get(`${API_URL}/api/admin/drivers/${formData.driver_id}`, {
                headers: { Authorization: `Bearer ${token}` },
            })
                .then(response => {
                    setDriverInfo(response.data.data);
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        localStorage.removeItem("authToken");
                        setToken(null);
                        navigate("/");
                    }
                    console.error("Error fetching driver details:", error);
                    setDriverInfo(null);
                });
        }
    }, [formData.driver_id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");
        setIsSubmitted(true);

        try {
            const response = await axios.post(`${API_URL}/api/admin/vehicles/create`, formData, {
                headers: { Authorization: `Bearer ${token}` },
            });

            alert(response.data.message);
            navigate(`/vehicles/${response.data.vehicle.id}`);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem("authToken");
                setToken(null);
                navigate("/");
            }
            if (error?.response?.data?.errors) {
                const errors = error.response.data.errors;
                const errorMessages = Object.keys(errors)
                    .map(key => errors[key].join(" "))
                    .join(" ");
                setError(errorMessages);
            } else if (error?.response?.data?.message) {
                setError(error.response.data.message);
            } else {
                setError("An error occurred. Please try again later.");
            }
        } finally {
            setIsSubmitted(false);
        }
    };

    return (
        <div className="container-fluid mt-3">
            <Breadcrumb>
                <Breadcrumb.Item href="/vehicles">Vehicle List</Breadcrumb.Item>
                <Breadcrumb.Item active>Create Vehicle</Breadcrumb.Item>
            </Breadcrumb>
            <h2 className="text-center">
                Create a new vehicle
                {driverInfo &&
                    <>
                        {' for '}
                        <Link to={`/drivers/${driverInfo.id}`}>
                            #{driverInfo.id}{' '}
                            {driverInfo
                                ? driverInfo.company
                                    ? driverInfo.company.business_name
                                    : driverInfo.name
                                : ''}
                        </Link>
                    </>
                }
            </h2>
            {error && <div className="alert alert-danger">{error}</div>}
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={handleSubmit} className="needs-validation" noValidate>
                                {!formData.driver_id && (
                                    <div className="mb-3">
                                        <label htmlFor="driver_id" className="form-label">Driver ID</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="driver_id"
                                            name="driver_id"
                                            value={formData.driver_id}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                )}
                                <div className="mb-3">
                                    <label htmlFor="year" className="form-label">Year</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="year"
                                        name="year"
                                        value={formData.year}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="make" className="form-label">Make</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="make"
                                        name="make"
                                        value={formData.make}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="model" className="form-label">Model</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="model"
                                        name="model"
                                        value={formData.model}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="color" className="form-label">Color</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="color"
                                        name="color"
                                        value={formData.color}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="plate_number" className="form-label">Plate Number</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="plate_number"
                                        name="plate_number"
                                        value={formData.plate_number}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="truck_type_id" className="form-label">Truck Type</label>
                                    <select
                                        className="form-control"
                                        id="truck_type_id"
                                        name="truck_type_id"
                                        value={formData.truck_type_id}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="">Select Truck Type</option>
                                        <option value="1">Pickup Truck</option>
                                        <option value="2">Flatbed Truck</option>
                                        <option value="3">Cube Van</option>
                                        <option value="4">Cargo Van</option>
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="box_or_deck_length" className="form-label">Box or Deck Length</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="box_or_deck_length"
                                        name="box_or_deck_length"
                                        value={formData.box_or_deck_length}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="cargo_insurance" className="form-label">Cargo Insurance ($)</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="cargo_insurance"
                                        name="cargo_insurance"
                                        value={formData.cargo_insurance}
                                        onChange={handleChange}
                                    />
                                </div>
                                <button type="submit" className="btn btn-primary">
                                    {isSubmitted
                                        ? <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />&nbsp;Creating...</>
                                        : "Create"}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withAuth(VehicleCreate);
