import React, { useState, useContext } from 'react';
import axios from 'axios';
import { API_URL } from '../../constants';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from "../AuthContext";
import withAuth from "../../auth";
import Spinner from "react-bootstrap/Spinner";
import InputMask from "react-input-mask";

const CreateUser = () => {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        phone_num: ''
    });
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const {token, setToken} = useContext(AuthContext);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setError('');
        setIsSubmitted(true);
        try {
            const response = await axios.post(`${API_URL}/api/admin/users/create`, formData, {
                headers: {'Authorization': `Bearer ${token}`}
            });

            if (response.data.message) {
                alert(response.data.message);
                navigate('/users');
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                setToken(null);
                navigate('/');
            }
            if (error?.response?.data?.errors) {
                const errors = error.response.data.errors;
                const errorMessages = Object.keys(errors).map(key => errors[key].join(' ')).join(' ');
                setError(errorMessages);
            } else if (error?.response?.data?.message) {
                setError(error.response.data.message);
            } else {
                setError('An error occurred. Please try again later.');
            }
        } finally {
            setIsSubmitted(false);
        }
    };

    return (
        <div className="container mt-5">
            <h1 className="text-center">Create a new customer</h1>
            {error && <div className="alert alert-danger">{error}</div>}
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body" align={'center'}>
                            <form onSubmit={handleSubmit} className="needs-validation" noValidate>
                                <div className="mb-3">
                                    <label htmlFor="first_name" className="form-label">First Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="first_name"
                                        name="first_name"
                                        value={formData.first_name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="last_name" className="form-label">Last Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="last_name"
                                        name="last_name"
                                        value={formData.last_name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="password" className="form-label">Password</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="password"
                                        name="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="phone_num" className="form-label">Phone Number</label>

                                    <InputMask className='form-control'
                                               id="phone_num"
                                               mask="+9 (999) 999-9999"
                                               placeholder="+1 (999) 999-9999"
                                               name="phone_num"
                                               value={formData.phone_num}
                                               onChange={handleChange}
                                               required
                                    />
                                </div>
                                <button type="submit" className="btn btn-primary">
                                    {isSubmitted
                                        ? <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />&nbsp;Creating...</>
                                        : "Create"
                                    }
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withAuth(CreateUser);
