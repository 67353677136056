export const formatPhoneNumberWithMask = (value, mask) => {
    // Remove all non-numeric characters
    const digits = value.replace(/\D/g, '');

    let formatted = '';
    let digitIndex = 0;

    // Replace placeholders in the mask with digits
    for (let i = 0; i < mask.length; i++) {
        if (digitIndex >= digits.length) break;

        if (mask[i] === '9') {
            formatted += digits[digitIndex];
            digitIndex++;
        } else {
            formatted += mask[i];
        }
    }

    return formatted;
};