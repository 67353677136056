import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import withAuth from "../../auth";
import { API_URL } from '../../constants';
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import { AuthContext } from "../AuthContext";

const DriverPerformanceStatistics = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const period = searchParams.get('period') || 'this_month';

    const [statistics, setStatistics] = useState([]);
    const [totals, setTotals] = useState({});
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { token, setToken } = useContext(AuthContext);

    useEffect(() => {
        fetchStatistics();
    }, [period]);

    const fetchStatistics = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${API_URL}/api/admin/drivers/performance`, {
                params: {
                    period: period,
                },
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            setStatistics(response.data.data);
            setTotals(response.data.totals);
        } catch (error) {
            console.error("There was an error fetching the statistics!", error);
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                setToken(null);
                navigate('/');
            }
        } finally {
            setLoading(false);
        }
    };

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-CA', {
            style: 'currency',
            currency: 'CAD',
        }).format(value);
    };

    const formatDistance = (value) => {
        return new Intl.NumberFormat('en-CA', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(value);
    };

    return (
        <div className="container-fluid mt-3">
            <h2 className="text-center">Driver Performance Statistics</h2>
            <div className="form-group text-center">
                <span>Period: </span>
                {['this_month', 'last_month', 'year', 'all'].map((timePeriod, index) => (
                    <React.Fragment key={timePeriod}>
                        <Link
                            to={`?period=${timePeriod}`}
                            className={`period-link ${period === timePeriod ? 'active' : ''}`}
                        >
                            {timePeriod
                                .replace('_', ' ')
                                .replace('this month', 'This Month')
                                .replace('last month', 'Last Month')
                                .replace('year', 'Year')
                                .replace('all', 'All')}
                        </Link>
                        {index < 3 && <span> / </span>}
                    </React.Fragment>
                ))}
            </div>
            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : (
                <div className="table-responsive">
                    <table className="table table-sm table-striped text-center text-nowrap">
                        <thead>
                        <tr>
                            <th>Driver ID</th>
                            <th>Completed</th>
                            <th>Total Earnings</th>
                            <th>Avg Cost/Pickup</th>
                            <th>Total Distance</th>
                            <th>Total Pickups</th>
                            <th>Canceled</th>
                            <th>Canceled by Driver</th>
                        </tr>
                        <tr>
                            <th>Totals</th>
                            <th>{totals.count_completed}</th>
                            <th>{formatCurrency(totals.total_driver_earnings)}</th>
                            <th>{formatCurrency(totals.average_cost_per_pickup)}</th>
                            <th>{formatDistance(totals.total_distance)} km</th>
                            <th>{totals.count_all}</th>
                            <th>{totals.count_canceled}</th>
                            <th>{totals.count_cancelled_by_driver}</th>
                        </tr>
                        </thead>
                        <tbody className="text-wrap">
                        {statistics.map((stat) => (
                            <tr key={stat.driver_id}>
                                <td>
                                    <Link to={`/drivers/${stat.driver_id}`}>
                                        {stat.driver_id}
                                    </Link>
                                    <br/>
                                    {stat.company_name ? <><i className="fa fa-building-o"/> {stat.company_name}</> : stat.driver_name}
                                </td>
                                <td className="align-middle">{stat.count_completed}</td>
                                <td className="align-middle">{formatCurrency(stat.total_driver_earnings)}</td>
                                <td className="align-middle">{formatCurrency(stat.average_cost_per_pickup)}</td>
                                <td className="align-middle">{formatDistance(stat.total_distance)}</td>
                                <td className="align-middle">{stat.count_all}</td>
                                <td className="align-middle">{stat.count_canceled}</td>
                                <td className="align-middle">{stat.count_cancelled_by_driver}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            )}
            <style jsx>{`
                .period-link {
                    color: goldenrod;
                    margin: 0 5px;
                    text-decoration: none;
                    cursor: pointer;
                }
                .period-link.active {
                    color: blue;
                    font-weight: bold;
                }
                .period-link:hover {
                    text-decoration: underline;
                }
            `}</style>
        </div>
    );
};

export default withAuth(DriverPerformanceStatistics);
